import * as ReactDOM from 'react-dom/client';

import { httpClientConfig, firebaseService } from '@trader/services';
import { IRootStoreInstance, rootStore } from '@trader/store';
import { ErrorHandler } from '@trader/components';
import { appConfigUtils, setupAuthTokensToStore } from '@trader/utils';

import { App } from './app';

const renderApp = () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  try {
    setupAuthTokensToStore<IRootStoreInstance>(rootStore);

    httpClientConfig.initialize({
      getAccessToken: () => rootStore.auth.tokens.idToken,
      getTokenType: () => rootStore.auth.tokens.tokenType,
      getSessionId: () => rootStore.auth.tokens.sessionId,
      getBrand: () => appConfigUtils.getCurrentBrand(),
      getApiType: () => appConfigUtils.getCurrentApiType(),
      getUserAccount: () => {
        const activeTradingAcc = rootStore.user.activeTradingAcc();
        return {
          serverName: activeTradingAcc?.serverName || '',
          platformLogin: activeTradingAcc?.platformLogin || '',
          product: rootStore.user.getAccountProduct() || '',
        };
      },
      createNotification: (message, variant) => {
        rootStore.notifications.add({
          message,
          options: {
            variant,
          },
        });
      },
      refreshToken: async () => {
        return await rootStore.auth.authRefreshTokenAsync.run();
      },
      logout: () => rootStore.auth.logOut(),
    });

    firebaseService.initialize();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    root.render(<App />);
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    root.render(<ErrorHandler />);

    throw err;
  }
};

renderApp();
